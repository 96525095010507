// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-faqs-and-guidelines-js": () => import("./../../../src/pages/about/faqs-and-guidelines.js" /* webpackChunkName: "component---src-pages-about-faqs-and-guidelines-js" */),
  "component---src-pages-about-gallery-js": () => import("./../../../src/pages/about/gallery.js" /* webpackChunkName: "component---src-pages-about-gallery-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-cocktails-and-events-js": () => import("./../../../src/pages/events/cocktails-and-events.js" /* webpackChunkName: "component---src-pages-events-cocktails-and-events-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-photo-and-video-shoots-js": () => import("./../../../src/pages/events/photo-and-video-shoots.js" /* webpackChunkName: "component---src-pages-events-photo-and-video-shoots-js" */),
  "component---src-pages-events-private-events-js": () => import("./../../../src/pages/events/private-events.js" /* webpackChunkName: "component---src-pages-events-private-events-js" */),
  "component---src-pages-events-weddings-js": () => import("./../../../src/pages/events/weddings.js" /* webpackChunkName: "component---src-pages-events-weddings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-pages-venues-cafe-js": () => import("./../../../src/pages/venues/cafe.js" /* webpackChunkName: "component---src-pages-venues-cafe-js" */),
  "component---src-pages-venues-index-js": () => import("./../../../src/pages/venues/index.js" /* webpackChunkName: "component---src-pages-venues-index-js" */),
  "component---src-pages-venues-mansion-js": () => import("./../../../src/pages/venues/mansion.js" /* webpackChunkName: "component---src-pages-venues-mansion-js" */),
  "component---src-pages-venues-the-loggia-restaurant-js": () => import("./../../../src/pages/venues/the-loggia-restaurant.js" /* webpackChunkName: "component---src-pages-venues-the-loggia-restaurant-js" */),
  "component---src-templates-events-inner-general-mixed-js": () => import("./../../../src/templates/events-inner-general-mixed.js" /* webpackChunkName: "component---src-templates-events-inner-general-mixed-js" */),
  "component---src-templates-events-inner-specific-js": () => import("./../../../src/templates/events-inner-specific.js" /* webpackChunkName: "component---src-templates-events-inner-specific-js" */),
  "component---src-templates-floor-specific-js": () => import("./../../../src/templates/floor-specific.js" /* webpackChunkName: "component---src-templates-floor-specific-js" */),
  "component---src-templates-room-specific-js": () => import("./../../../src/templates/room-specific.js" /* webpackChunkName: "component---src-templates-room-specific-js" */),
  "component---src-templates-store-specific-js": () => import("./../../../src/templates/store-specific.js" /* webpackChunkName: "component---src-templates-store-specific-js" */),
  "component---src-templates-venue-specific-js": () => import("./../../../src/templates/venue-specific.js" /* webpackChunkName: "component---src-templates-venue-specific-js" */)
}

